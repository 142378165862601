import React from "react"
// import {TwitterIcon, TwitterShareButton} from 'react-share';

import shapeFour from "../../../../images/athena-04-resilience/05-flourishing/shape-three.png"
import play from "../../../../images/athena-04-resilience/05-flourishing/play.png"

import styles from "../flourishing.module.scss"

const sectionFour = () => {
  return (
   <div>
      <div className={styles.articleSection}>
        <div className={styles.column}>
          <div className={`${styles.columnMisc}`}>
            <div className={`${styles.imageDetails}`}>
            <br/>
            <br/>
              <img src={play} alt="dropbox" />
              <p className="a-h-5">Out of Office: Spirit of Play Guide</p>
              <a href="https://www.getoutofoffice.network/shop/p/a-spirit-of-play-for-your-workday" target="_blank" className={`button button--small button--block`} rel="noreferrer">Download</a>
            </div>
            <img className={styles.shapeMarginTop} src={shapeFour} alt="shape" />
          </div>
        </div>
        <div className={styles.columnLine}></div>
        <div className={styles.column}>
          <div className={styles.columnContent}>
            <div className={`${styles.columnContentSplit}`}>
              <h5>A<span>J</span></h5>
              <p><strong>You've spent years thinking about what makes us individually and collectively flourish. How do you approach the concept of joy?</strong></p>
              
              <h5><span>A</span>K</h5>
              <p>Joy is essential for a positive life. Especially in a world that often feels heavy, I believe that prioritizing joy in our working lives is an important step toward creating a safe, humane place where people can find lightness, playfulness and maybe even meaning and purpose in their work. Joy also has incredible powers. It sparks creative energy, which is essential for the way we work, and is the force that drives us to be inventive, imaginative, and solve hard problems. It creates a sense of purpose and fulfillment and, particularly when sharing joy with other people, creates connection and a sense of belonging.</p>

              <div className={styles.mobileImageDetails}>
                <img src={play} alt="play" />
                <p className="a-h-5">Out of Office: Spirit of Play Guide</p>
                <a href="https://www.getoutofoffice.network/shop/p/a-spirit-of-play-for-your-workday" target="_blank" className={`button button--small button--block`} rel="noreferrer">Download</a>
              </div>


              <h5>A<span>J</span></h5>
              <p><strong>What is your favorite tool that you've developed through Out Of Office?</strong></p>

              <h5><span>A</span>K</h5>
              <p>Related to Joy, I created a <a href="https://dropbox.design/resource/spirit-of-joy-planner" target="_blank" rel="noreferrer">Spirit of Joy</a> Planner and group facilitation guide, to design space for joy, and plant the seeds for building a culture that takes joy as a priority. It focuses on the following conditions to help individuals and teams foster the experience of joy:

              <ul>
                <li>A support system: This can come in the form of having the right people around you, who energize you.</li>
                <li>Clarity of direction and achievable goals - which can come in the form of setting goals for yourself, as well as having clarity in the direction of your work, and being aligned with where your team and company is heading.</li>
                <li>Space for your daily rituals, like taking a daily walk, and respecting your personal rhythm and integrating that into your work.</li>
                <li>Inspiration. Inspiration can come from conversations with others, music, reading, being in nature, visiting a museum or leaving your everyday context, and is often connected to experiencing a state of flow. Making time for what inspires you is a key condition that helps to spark joy.</li>
              </ul>

              Apart from that, one of my favorite tools is the <a href="https://dropbox.design/resource/corporate-tarot-connection-cards" target="_blank" rel="noreferrer">Corporate Tarot Cards</a> I designed together with Dropbox. They power connection through human stories and play. By sharing our stories with one another we explore our similarities, uncover differences, and learn to connect on a deeper level. I use them all the time, from sitting together with friends in order to open up deeper conversations, to working with new teams. If you're interested, you can order a physical set <a href="https://www.getoutofoffice.network/shop/cards" target="_blank" rel="noreferrer">here</a>.<br/><br/>
              Not a tool, but a space that brings me a lot of joy right now is something I've started just recently. It's a <a href="https://outofofficenetwork.substack.com/p/a-new-era-the-life-centered-economy?sd=pf" target="_blank" rel="noreferrer">Learning + Playing Community</a> to explore new ways of working and living for strategists, designers, researchers and other workplace optimists across the globe. It's a place to co-create new practices and ideas around making work more sustainable and regenerative while fostering a culture of exploration and well-being in our workplaces and our daily lives.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default sectionFour
