import React from "react"
// import {TwitterIcon, TwitterShareButton} from 'react-share';

import shapeFive from "../../../../images/athena-04-resilience/05-flourishing/shape-five.png"
import dropBoxKits from "../../../../images/athena-04-resilience/05-flourishing/design-kits.jpg"

import styles from "../flourishing.module.scss"

const sectionFive = () => {
  return (
    <div>
      <div className={styles.articleSection}>
        <div className={styles.column}>
          <div className={`${styles.columnContent} ${styles.columnContentLeft}`}>
            <div className={`${styles.columnContentSplit}`}>
              <h5>A<span>J</span></h5>
              <p><strong>You also have deep experience in helping clients build community. In your perspective, what does it mean to have a supportive community at work?</strong></p>
              <h5><span>A</span>K</h5>
              <p>Having a supportive community at work to me means being accepted fully within a group of people where members identify with one another and share common values, feel like they belong, feel emotionally safe and included and genuinely care. It means having a network of people that is keen on trying something new together, provide opportunities and safe spaces for growth and discovery and that keeps us inspired.</p>
              <h5>A<span>J</span></h5>
              <p><strong>How does making space in our lives to explore and play enhance a feeling of belonging with the people and the world around us?</strong></p>
              
              <h5><span>A</span>K</h5>
              <p>
              Belonging is fostered through connections and a shared sense of purpose. The principles of exploration and play allow us to build new connections and engage with people in a low-stakes environment, which also makes it easier to show different parts of ourselves.
              <br/><br/>
              For teams, moments of play can foster stronger bonds, help people get to know each other better, and create an environment to bring in levity and fun. Awareness of a team member's life both within and outside of work can help us appreciate our similarities, explore differences and, especially in a remote world, see that we're not just digital representations but holistic human beings.
              <br/><br/>
              "Community Play" (as I like to call it) and “special programs" are a way to explore, collaborate and with that, build networks, and unleash creative energy as a team or group of people. They provide us with opportunities to build healthy bridges between people, making encounters more human by forming a common purpose. A recent example is a community program I formed for <a href="https://kyu.com/" target="_blank" rel="noreferrer">kyu</a>, a holding company and collective of best-in-class creative companies. I designed a program/playground that enables cross-company learning, co-creation, and catalyzes a collective approach and POV to the now and future of work. Activities included running research projects, designing tools, and bringing out collective intelligence so that practitioners across different companies have a space to experiment, co-create, and learn from each other. And with Dropbox Design, I built different programs, from a monthly "Design Salon" co-hosted by a rotation of different teams, to "Culture Kits" and a <a href="https://dropbox.design/" target="_blank" rel="noreferrer">Design Blog</a> that helped employees explore themes they were curious about beyond their day-to-day work.
              <br/><br/>
              You can try to build these qualities into your culture by having ping pong tables, free pizza or taco lunches, but ultimately what we will have to ask is: Do people have the flexibility and autonomy to make work work for them? Do they have opportunities and networks that keep them inspired, learning and growing? And do they feel like they belong?
              </p>
              
        
              <div className={styles.mobileImageDetails}>
                <img src={dropBoxKits} alt="intro" />
              <p className="a-h-5">Dropbox Design: Culture Kits </p>
              <a href="https://dropbox.design/resources" target="_blank" className={`button button--small button--block ${styles.button}`} rel="noreferrer">
              Download</a>
              </div>

            </div>
          </div>
        </div>
        <div className={styles.columnLine}></div>
        <div className={styles.column}>
          <div className={`${styles.columnMisc}`}>
            <img className={`${styles.shapeRight} ${styles.shape}`} src={shapeFive} alt="Shape" />
            <br/><br/>
            <div className={styles.imageDetails}>
              <img src={dropBoxKits} alt="intro" />
              <p className="a-h-5">Dropbox Design: Culture Kits </p>
              <a href="https://dropbox.design/resources" target="_blank" className={`button button--small button--block ${styles.button}`} rel="noreferrer">
              Download</a>
            </div> 
          </div>
        </div>
      </div>
    </div>
  )
}
export default sectionFive
