import React from "react"
// import {TwitterIcon, TwitterShareButton} from 'react-share';

import outOfOffice from "../../../../images/athena-04-resilience/05-flourishing/out-of-office.jpg"
import styles from "../flourishing.module.scss"

const sectionTwo = () => {
  return (
    <div>
      <div className={`${styles.articleSection}`}>
        <div className={styles.column}>
          <div className={styles.columnMisc}>
            <div className={styles.imageDetails}>
              <img src={outOfOffice} alt="intro" />
              <p className="a-h-5">Out of Office: Digital Toolkits</p>
              <a href="https://www.getoutofoffice.network/shop/virtual" target="_blank" className={`button button--small button--block`} rel="noreferrer">Download</a>
            </div>
          </div>
        </div>
          <div className={styles.columnLine}></div>
            <div className={styles.column}>
              <div className={styles.columnContent}>
                <div className={styles.columnContentSplit}>
                  <h5>A<span>J</span></h5>
                  <p><strong>American work culture notoriously celebrates many habits that other countries avoid. How do your ideas about play, wellbeing, and exploration perhaps advocate for a new kind of work culture here in the States?</strong></p>
                
                  <h5><span>A</span>K</h5>
                  <p>By moving to the US, and learning the vocabulary of “workism” and being exposed to its always-on culture of work, I realized that mystery, wonder and surprise are the emotions most undermined by it. If we are absorbed by our work and the daily grind, we avoid creating space for exploration, discovery, serendipity and wonder, and with that, space for inspiration and new ideas. At the same time, workplace productivity suffers and people feel burned out, disengaged, depleted and ultimately, quit their jobs.
                  <br/><br/>
                  That's why now more than ever, I believe that workplaces of the future must not only provide for the productivity needs of their people, but must also help people create and grow relationships and networks, build a sense of belonging, and foster an environment in which they can feel inspired and explore different aspects of themselves - which are all important components for us to thrive and be well.
                  <br/><br/>
                  I believe that we, as organizations as well as individuals, need to shift our perspective to play and exploration not being distractions. They are a source of energy and empowerment. Exploration is the creative engine that makes us see things in a new light, fuels joy, expands what we believe is possible, and helps us learn and grow. Without creating space and permission for us to explore, learn and grow, we start to feel stagnant, frustrated, and ineffective.
                  <br/><br/>
                  With my work, I help individuals and organizations build those qualities into their culture by designing and implementing strategies, programs and tools to foster deeper connections, build networks that inspire, and provide opportunities to explore, contribute, learn and grow.</p>

                  <div className={styles.mobileImageDetails}>
                    <img src={outOfOffice} alt="intro" />
                    <p className="a-h-5">Out of Office: Digital Toolkits</p>
                    <a href="https://www.getoutofoffice.network/shop/virtual" target="_blank" className={`button button--small button--block`} rel="noreferrer">Download</a>
                  </div>

                </div>
              </div>
            </div>
          </div>
        
      </div>
  )
}
export default sectionTwo