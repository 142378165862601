import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"

import Layout, { athenaTheme } from "../../../components/layout"

import ScrollProgressBar from "../../../components/scrollProgressBar"
import {TwitterIcon, TwitterShareButton, LinkedinIcon, LinkedinShareButton, FacebookShareButton, FacebookIcon} from 'react-share';

import Hero from "./article-05/hero"
import SectionOne from "./article-05/sectionOne"
import SectionTwo from "./article-05/sectionTwo"
import FullWidthQuoteOne from "./article-05/fullWidthQuoteOne"
import SectionThree from "./article-05/sectionThree"
import SectionFour from "./article-05/sectionFour"
import FullWidthQuoteTwo from "./article-05/fullWidthQuoteTwo"
import SectionFive from "./article-05/sectionFive"

import footerImage from "../../../images/athena-04-resilience/05-flourishing/footer-shape.png"

import PreFooter from "../../../components/athena/preFooter"
import Contributors from "../../../components/athena/contributors"
import MoreArticles from "../../../components/athena/moreArticlesJoy"

import styles from "./flourishing.module.scss"

let theme = {
  ...athenaTheme,
  background: styles.background,
  foreground: styles.foreground,
}


const Article = ({ data }) => {
  const article = data.craft.article
  const seo = article.seomatic

  return (
    <Layout seo={seo} mode="athena" theme={theme}>
      <Helmet>
        <body className={`athena ${styles.body}`}/>
      </Helmet>
      <article>
        <ScrollProgressBar />
        <div className={styles.article}>
        
        <Hero />
        
          <div className={`${styles.articleBody}`}>
            <div className={`${styles.articleBodyTop}`}>
              <div className={styles.readingTime}>
                <p className={styles.reading}>15 min reading time</p>
                <p className={styles.readingMobile}>15 min read</p>
                <div className={styles.socialContainer}>
                  <p className={styles.share}>Share —</p>
                  <TwitterShareButton
                  url={'https://zeusjones.com/athena/articles/flourishing-in-and-out-of-office'}
                  title={article.title}
                  className={styles.shareButtons}
                  via = 'zeusjones'
                  >
                    <TwitterIcon />
                  </TwitterShareButton>
                
                  <LinkedinShareButton
                  url={'https://zeusjones.com/athena/articles/flourishing-in-and-out-of-office'}
                  title={article.title}
                  className={styles.shareButtons}
                  via = 'zeusjones'
                  >
                    <LinkedinIcon />
                  </LinkedinShareButton>
              
                  <FacebookShareButton
                  url={'https://zeusjones.com/athena/articles/flourishing-in-and-out-of-office'}
                  title={article.title}
                  className={styles.shareButtons}
                  via = 'zeusjones'
                  >
                    <FacebookIcon />
                  </FacebookShareButton>
                </div>
              </div>
              <h2>A <span>n</span>ew, mo<span>r</span>e j<em>o</em>yful and <span>s</span>us<span>t</span>ai<span>n</span>able <span>c</span>oncept of <em>r</em>esili<span>e</span>n<span>c</span>e isn't j<span>u</span>st a pe<span>r</span>sonal res<span>p</span>on<span>s</span>ibility. I<span>t</span>'s a <span>c</span>ultural o<span>n</span>e, and deeply e<span>n</span>twi<span>n</span>ed wi<span>t</span>h the <span>c</span>han<span>g</span>in<span>g</span> natu<span>r</span>e <em>o</em>f wo<span>r</span>k.</h2>
            </div>

            <SectionOne />
            <SectionTwo />
            <FullWidthQuoteOne />
            <SectionThree />
            <SectionFour />
            <FullWidthQuoteTwo />
            <SectionFive />

            <img className={styles.footerImage} src={footerImage} alt="" />
            <Contributors
              zeus={article.zeusContributors}
              other={article.otherContributors}
            />
            
            <div className={styles.moreFrom} >
            <MoreArticles 
              id={article.id} 
              issue={article.issue[0].id} 
            />
            </div>
          </div>

          

        </div>
        
      </article> 
      
    <PreFooter style={{ marginTop: 0 }} />
  </Layout>
  )
}

export default Article

export const query = graphql`
  query($slug: String!) {
    craft {
      article: entry(slug: [$slug], section: "athenaArticles") {
        id
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_athenaArticles_athenaArticleCustom_Entry {
          articleSubTitle
          issue {
            id
            title
          }
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_16x9 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
              ratio_4x3 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
            }
          }
          zeusContributors {
            id
            title
            uri
          }
          otherContributors {
            ... on Craft_otherContributors_TableRow {
              name
              website
            }
          }
        }
      }
    }
  }
`
