import React from "react"
import {TwitterIcon, TwitterShareButton} from 'react-share';

import shapeThree from "../../../../images/athena-04-resilience/05-flourishing/shape-two.png"
import workSilhouette from "../../../../images/athena-04-resilience/05-flourishing/silhouette.jpg"

import styles from "../flourishing.module.scss"

const sectionThree = () => {
  return (
    <div>
      <div className={styles.articleSection}>
        <div className={styles.column}>
          <div className={`${styles.columnContent} ${styles.columnContentLeft}`}>
            <div className={`${styles.columnContentSplit}`}>
              <h5>A<span>J</span></h5>
              <p><strong>Remote work has quickly become a reality for millions across the globe. What are some of the new challenges and opportunities for occupational wellbeing that come along with working in a remote environment?</strong></p>

              <h5><span>A</span>K</h5>
              <p>The beauty of remote work is that the flexibility and autonomy allow for different ways of living. Flexibility and autonomy in how we design our work enables us to integrate different parts of our lives and design a work environment that's more suited to our individual needs. Remote work allows us to introduce more modalities of how and where we work, and allow for more diverse ways of living. It gives us time and space to do things outside of work, and on top of that, communicates trust, which is key for occupational well-being.
              <br/><br/>
              On the other hand, there's certainly challenges that come with remote work. Some miss the work-life boundaries. When you live in your work space, it's easy to just keep working. So environmental behavioral changes are an interesting challenge - and with that, the tension of flexibility and boundaries to working from home. Others report feeling socially isolated and are experiencing overload, stress, and disengagement. In a recent internal culture and future of work client project, as well as conversations within my network, I heard people feel depleted, or as a friend of mine called it “creatively burned-out.” I heard leaders and managers say they feel like they can't feed their teams with more of their personal energy, and see chronically low well-being around and within themselves. But I don't think that's purely a “remote work” challenge but a challenge connected to the impact of a global pandemic, and us shifting to a new way of working that we all still don't have fully figured out. Change is hard, so it will take time for us to adjust to new ways of working.</p>

              <h5>A<span>J</span></h5>
              <p><strong>How has the advent of remote work given new meaning to your writing and research for “Out of Office”?</strong></p>

              <h5><span>A</span>K</h5>
              <p>Out of Office isn't just about travel and living in different countries, but about looking at the world with open eyes, expanding our experiences and networks, and making space for the holistic and creative human beings we all have inside us.
              <br/><br/>
              Especially right now, we're in a period of profound transformation, where everything about the way we work, and the place of work in our lives, is being redefined. We can see this shift in the Great Resignation and in the trend of Quiet Quitting. The past years have left many of us rethinking the role of work in our lives and craving a model that includes work but also includes nurturing our physical and mental health, our relationships and communities, our hobbies, and our desire to slow down, explore and kindle creativity and wonder.
              <br/><br/>
              I believe that with more flexible forms of working, whether that's remote or hybrid work becoming more “mainstream” thanks to the pandemic, a more creative way of living and working has become more accessible for more people. We are now able to design our lives more creatively, and more suited to what it is we personally need and is nourishing to us. I believe that we are stepping into a new era of work, where we can make work more sustainable and regenerative. That's what excited me most about the future of work.</p>
            </div>
          </div>
        </div>
        <div className={styles.columnLine}></div>
        <div className={`${styles.column}`}>
          <div className={styles.columnMisc}>
            <img className={styles.shape} src={shapeThree} alt="Shape" />
            <h3 className={`${styles.shareQuote} ${styles.alignRight}`}>
              <span>I</span>
              <span>believe</span>
              <span><b>t</b>hat</span>
              <span>we</span>
              <span>a<b>r</b>e</span>
              <span>steppin<b>g</b></span>
              <span>i<b>n</b>to</span>
              <span>a</span>
              <span>n<b>e</b>w</span>
              <span>era</span>
              <span><em>o</em>f</span>
              <span>wo<b>r</b>k,</span>
              <span>whe<b>r</b>e</span>
              <span>we</span>
              <span><b>c</b>an</span>
              <span>make</span>
              <span>wo<b>r</b>k</span>
              <span>mo<b>r</b>e</span>
              <span>s<b>u</b>stai<b>n</b>able</span>
              <span>and</span>
              <span><b>r</b>egene<b>r</b>ati<b>v</b>e.</span>
              <TwitterShareButton
                url={"https://zeusjones.com/athena/articles/flourishing-in-and-out-of-office"}
                title="I believe that we are stepping into a new era of work, where we can make work more sustainable and regenerative."
                className={styles.tweetButton}
                via = "zeusjones"
                >
                  <span>Tweet <TwitterIcon /></span>
              </TwitterShareButton>
            </h3>
          </div>
        </div>
      </div>
      <div className={`${styles.imageWindow}`}>
        <img src={workSilhouette} alt="iphone" />
      </div>
    </div>
  )
}
export default sectionThree
