const isBrowser = typeof window !== "undefined"

export const importAll = (r) => {
  return r.keys().map(r);
}
const imagePaths = require.context('./hero-scroll', false, /\.png$/);
export const sequenceImages = importAll(imagePaths);

class EventEmitter {
  listeners = {}
  addListener(eventName, fn) {
    this.listeners[eventName] = this.listeners[eventName] || [];
    this.listeners[eventName].push(fn);
    return this;
  }
  on(eventName, fn) {
    return this.addListener(eventName, fn);
  }
  once(eventName, fn) {
    this.listeners[eventName] = this.listeners[eventName] || [];
    const onceWrapper = () => {
      fn();
      this.off(eventName, onceWrapper);
    }
    this.listeners[eventName].push(onceWrapper);
    return this;
  }
  off(eventName, fn) {
    return this.removeListener(eventName, fn);
  }
  removeListener (eventName, fn) {
    let lis = this.listeners[eventName];
    if (!lis) return this;
    for(let i = lis.length; i > 0; i--) {
      if (lis[i] === fn) {
        lis.splice(i,1);
        break;
      }
    }
    return this;
  }
  emit(eventName, ...args) {
    let fns = this.listeners[eventName];
    if (!fns) return false;
    fns.forEach((f) => {
      f(...args);
    });
    return true;
  }
  listenerCount(eventName) {
    let fns = this.listeners[eventName] || [];
    return fns.length;
  }
  rawListeners(eventName) {
    return this.listeners[eventName];
  }
}


class Canvas {
  constructor(e) {
    this.images = e.images;
    this.container = e.container;
    this.cover = e.cover;
    this.displayIndex = 0;
  }
  
  setup() {
    this.canvas = document.createElement("canvas");
    this.container.appendChild(this.canvas);
    this.ctx = this.canvas.getContext('2d')
    window.addEventListener('resize', () => this.resize());
    this.resize();
  }
  
  renderIndex(e) {
    if (this.images[e]) {
        return this.drawImage(e);
    }
    // Find closest loaded image
    for (var t = Number.MAX_SAFE_INTEGER, r = e; r >= 0; r--)
        if (this.images[r]) {
            t = r;
            break
        }
    for (var n = Number.MAX_SAFE_INTEGER, i = e, o = this.images.length; i < o; i++)
        if (this.images[i]) {
            n = i;
            break
        }
    this.images[t] ? this.drawImage(t) : this.images[n] && this.drawImage(n)
  }
  
  drawImage(e) {
    this.displayIndex = e;
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    const x = Math.floor((this.canvas.width - this.images[this.displayIndex].naturalWidth) / 2 );
    const y = Math.floor((this.canvas.height - this.images[this.displayIndex].naturalHeight) / 2 );
    this.ctx.drawImage(this.images[this.displayIndex], x, y);
    // if (this.cover) {
    //   this.drawImageCover(this.ctx, this.images[this.displayIndex]);
    // } else {
    //   this.ctx.drawImage(this.images[this.displayIndex], x, y);
    // }
  }
  
  resize() {
    const w = this.container.clientWidth;
    const h = this.container.clientHeight;
    this.canvas.style.height = `${h}px`;
    this.canvas.style.width = `${w}px`;
    if (window.innerWidth >= 2000) {
      this.canvas.width = w * 1.75;
      this.canvas.height = h * 1.75;
    }
    if (window.innerWidth <= 1999 && window.innerWidth >= 1390) {
      this.canvas.width = w * 1.25;
      this.canvas.height = h * 1.25;
    }
    if (window.innerWidth <= 1389 && window.innerWidth >= 900) {
      this.canvas.width = w * 1.75;
      this.canvas.height = h * 1.75;
    }
    if (window.innerWidth < 899 && window.innerWidth >= 700 ) {
      this.canvas.width = w * 2.25;
      this.canvas.height = h * 2.25;
    }
    if (window.innerWidth < 699 && window.innerWidth >= 500 ) {
      this.canvas.width = w * 3.5;
      this.canvas.height = h * 3.5;
    }
    if (window.innerWidth < 499 && window.innerWidth >= 400 ) {
      this.canvas.width = w * 5;
      this.canvas.height = h * 5;
    }
    if (window.innerWidth < 399 ) {
      this.canvas.width = w * 5.5;
      this.canvas.height = h * 5.5;
    }
    
    this.renderIndex(this.displayIndex);
  }
}
export class ImgLoader extends EventEmitter {
  constructor(opts) {
    super();
    this.images = opts.imgsRef;
    this.imageNames = opts.images;
    this.imagesRoot = opts.imagesRoot;
    this.sequenceLength = opts.images.length;
    this.priorityFranes = opts.priorityFrames;
    this.complete = false;
    this.loadIndex = 0;
    
    this.priorityQueue = this.createPriorityQueue();
    this.loadingQueue = this.createLoadingQueue();
    
    this.loadNextImage();
  }
  
  loadImage(e) {
    if (this.images[e]) {
      return this.loadNextImage();
    }
    const onLoad = () => {
      img.removeEventListener('load', onLoad);
      this.images[e] = img;
      
      if (e === 0) {
        console.log('first loaded');
        this.emit('FIRST_IMAGE_LOADED');
      }
      this.loadNextImage();
    }
    const img = new Image();
    img.addEventListener('load', onLoad);
    img.src = (this.imagesRoot ? this.imagesRoot : '') + this.imageNames[e];
    console.log('src', this.imagesRoot, img.src);
  }
  
  loadNextImage() {
    if (this.priorityQueue.length) {
      this.loadImage(this.priorityQueue.shift());
      if (!this.priorityQueue.length) {
        this.emit('PRIORITY_IMAGES_LOADED');
      }
    } else if (this.loadingQueue.length) {
        this.loadImage(this.loadingQueue.shift())
    } else {
      this.complete = true;
      this.emit('IMAGES_LOADED');
    }
  }
  
  createPriorityQueue() {
    const p = this.priorityFrames || [];
    if (!p.length) {
      p.push(0);
      p.push(Math.round(this.sequenceLength / 2));
      p.push(this.sequenceLength - 1);
    }
    return p;
  }
  
  createLoadingQueue() {
    return this.imageNames.map((s, i) => i).sort((e, n) => {
       return Math.abs(e - this.sequenceLength / 2) - Math.abs(n - this.sequenceLength / 2)
    });
  }
}

export class ScrollSequence {
  constructor(opts) {
    if (isBrowser) {
      this.opts = {
        container: 'body',
        starts: 'out',
        ends: 'out',
        imagesRoot: `${window.location.protocol}//${window.location.host}`,
        cover: false,
        ...opts
      }
    
      this.container = typeof opts.container === 'object' ? 
        opts.container : 
        document.querySelector(opts.container);

      this.scrollWith = !opts.scrollWith ? 
        this.container : 
        typeof opts.scrollWith === 'object' ? 
          opts.scrollWith : 
          document.querySelector(opts.scrollWith);

          console.log('opts', opts);
    
      this.images = Array(opts.images.length);
      this.imagesToLoad = opts.images;
      this.priorityFrames = opts.priorityFrames;
      
      this.loader = new ImgLoader({
        imgsRef: this.images,
        images: this.imagesToLoad,
        imagesRoot: this.opts.imagesRoot,
        priorityFrames: this.priorityFrames
      });

      // console.log('loader', this.loader, this.images, this.imagesToLoad);
      
      this.canvas = new Canvas({
        container: this.container,
        images: this.images,
        cover: this.opts.cover
      });

      this.init();
    }
  }
  init() {
    this.canvas.setup();
    this.loader.once('FIRST_IMAGE_LOADED', () => {
      this.canvas.renderIndex(0);
    })
    this.loader.once('PRIORITY_IMAGES_LOADED', () => {
      window.addEventListener('scroll', () => this.changeOnWindowScroll());
    })
    this.loader.once('IMAGES_LOADED', () => {
      console.log('Sequence Loaded');
      // window.addEventListener('scroll', () => this.changeOnWindowScroll());
    })
  }
  
  changeOnWindowScroll() {
    const step = 70 / (this.images.length - 1);
    const mapToIndex = Math.floor(this.percentScrolled / step);
    window.requestAnimationFrame(() => this.canvas.renderIndex(mapToIndex));
  }
  
  get percentScrolled() {
    const {starts, ends} = this.opts;
    const el = this.scrollWith;
    const doc = document.documentElement;
    const clientOffsety = doc.scrollTop || window.pageYOffset;
    const elementHeight = el.clientHeight || el.offsetHeight;
    const clientHeight = doc.clientHeight;
    let target = el;
    let offsetY = 0;
    do {
      offsetY += target.offsetTop;
      target = target.offsetParent;
    } while (target && target !== window);
    
    let u = (clientOffsety - offsetY);
    let d = (elementHeight + clientHeight)
    
    if (starts === 'out') u += clientHeight;
    if (ends === 'in') d -= clientHeight;
    if (starts === 'in') d -= clientHeight;
    
    // start: out, ends: out
    // const value = ((clientOffsety + clientHeight) - offsetY) / (clientHeight + elementHeight) * 100;
    
    //start: in, ends: out
    // const value = (clientOffsety - offsetY) / (elementHeight) * 100;
    
    //start: out, ends: in
    // const value = ((clientOffsety + clientHeight) - offsetY) / (elementHeight) * 100;
    
    // Start: in, ends: in
    // (clientOffsety - offsetY) / (elementHeight - clientHeight)
    
    const value = u / d * 100;
    return value > 100 ? 100 : value < 0 ? 0 : value;
  }
}
// END SCROLL_SEQUENCE CODE